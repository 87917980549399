import * as React from "react";
import { Link } from "gatsby";

import Bridge from "../../components/Bridge.js";
import Ch from "../../components/Chord.js";
import Chorus from "../../components/Chorus.js";
import Columns from "../../components/Columns.js";
import { Helmet } from "react-helmet";
import Layout from "../../components/Layout";
import PreChorus from "../../components/PreChorus.js";
import Verse from "../../components/Verse.js";

export default function BurnOut() {
  return (
    <Layout>
      <Helmet title="Rather Be Lonely | Michael Weslander + Taylor" />

      <h1>Rather Be Lonely</h1>

      <h2>Michael Weslander</h2>

      <h4>Key: Am</h4>

      <h4>Tempo: 132 (1/2 time)</h4>

      <Link to="/">List</Link>

      <Columns columns="2">
        <Verse>
          <p>
            <Ch>Am</Ch>This time each <Ch>C</Ch>year sneaks up on <Ch>F</Ch>me
          </p>
          <p>
            A <Ch>Am</Ch>slip and slide to a <Ch>C</Ch>black hole calendar
            <Ch>F</Ch> week
          </p>
          <p>
            A <Ch>Am</Ch>mile high ladder to <Ch>C</Ch>climb out the other
            <Ch>F</Ch> side
          </p>
          <p>
            <Ch>Am</Ch>Afraid that one of these <Ch>C</Ch>years the tears will
            have<Ch>F</Ch> dried
          </p>
        </Verse>
        <PreChorus>
          <p>
            <Ch>Am</Ch>I’d rather be stranded in the <Ch>G</Ch>middle
          </p>
          <p>
            Of a stormy <Ch>F</Ch>sea
          </p>
        </PreChorus>
        <Chorus>
          <p>
            <Ch>Am</Ch>I’d rather be lonely in a <Ch>G</Ch>city
          </p>
          <p>
            Where they don’t know <Ch>F</Ch>me
          </p>
        </Chorus>
        <Verse>
          <p>
            <Ch>Am</Ch>Every now and <Ch>C</Ch>then I’m hit with a <Ch>F</Ch>
            wave
          </p>
          <p>
            <Ch>Am</Ch>Listen back through a <Ch>C</Ch>batch of messages
            <Ch>F</Ch> saved
          </p>
          <p>
            <Ch>Am</Ch>Can’t believe the <Ch>C</Ch>year that reads on the
            <Ch>F</Ch> screen
          </p>
          <p>
            <Ch>Am</Ch>Wish that I could <Ch>C</Ch>share all the things i have
            <Ch>F</Ch> seen
          </p>
        </Verse>
        <PreChorus>
          <p>
            <Ch>Am</Ch>It feels if only I could <Ch>G</Ch>hold on
          </p>
          <p>
            To a fickle <Ch>F</Ch>dream
          </p>
        </PreChorus>
        <Chorus>
          <p>
            <Ch>Am</Ch>I’d rather be lonely in a <Ch>G</Ch>city
          </p>
          <p>
            Where they don’t know <Ch>F</Ch>me
          </p>
        </Chorus>
        <Bridge>
          <p>C (walk up)</p>
        </Bridge>
        <Chorus>
          <p>I’d rather be lonely in a city</p>
          <p>Where they don’t know me</p>
        </Chorus>
      </Columns>
    </Layout>
  );
}
